<style scoped>
#container {
  height: 100vh;
}

.diagonal-box {
  position: relative;
  background-image: linear-gradient(210deg, #2bd9eb 50%, #043561 50%);
}

h1 {
  word-break: break-word;
}
</style>
<template>
  <div id="container" class="diagonal-box d-flex">
    <v-row align="center" justify="center" class="pl-10 pr-10">
      <v-col md="8" lg="6">
        <v-card class="pa-5">
          <v-card-title>
            Password Reset
          </v-card-title>
          <v-card-text @keydown.enter="sendReset">
            <v-text-field :error-messages="passwordErrors" v-model="password" filled label="New Password" type="password" />
            <v-text-field v-model="passwordAgain" label="New Password Again" filled type="password" />
            <p>
              Passwords must be greater than 12 characters
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="6">
                <v-btn :loading="loading" @click="sendReset" block color="primary">
                  <v-icon small left>
                    fa-lock
                  </v-icon>
                  Reset Password
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { minLength, required } from 'vuelidate/lib/validators'
import event, { Events } from '../event'

export default {
  data: () => ({
    password: '',
    passwordAgain: '',
    loading: false
  }),
  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.$dirty) { return errors }
      if (!this.$v.password.required) { errors.push('Password is required') }
      if (!this.$v.password.minLength) { errors.push('Password must be > 12 in length') }
      if (!this.$v.passwordAgain.matches) { errors.push('Passwords do not match') }

      return errors
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(12)
    },
    passwordAgain: {
      matches: (value, ctx) => {
        return value === ctx.password
      }
    }
  },
  methods: {
    sendReset () {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.loading = true
      this.$axios.put('/v1/user/reset', { token: this.$route.params.token, password: this.password })
        .then(() => {
          event.emit(Events.SUCCESS, 'Your password has been reset!')
          this.$router.push('/login')
        })
        .catch(() => {
          event.emit(Events.ERROR, 'Your password token is not longer valid.  Please request a new token.')
          this.$router.push('/login')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
